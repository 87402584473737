<template>
  <div>
    <!-- <div>
      <PrintFab class="noprint modal-printer">
        <b-button size="sm" variant="none" @click="generateReport()">
          <b-icon-printer></b-icon-printer>
        </b-button>
      </PrintFab>
    </div> -->
    <div>
      <!-- <template v-if="question_test_id">
        <div class="upper-container">
          <div class="specification-table-header">
            <b-button class="btn-go-back noprint" @click="$router.go(-1)"
              ><b-icon
                class="btn-icon"
                icon="arrow-left-circle"
                scale=".8"
              ></b-icon>
              Volver</b-button
            >
            <div class="specification-table-title">
              Tabla de Especificaciones
            </div>
            <b-button
              class="printer noprint"
              variant="none"
              @click="generateReport()"
              ><b-icon icon="printer"> </b-icon> Imprimir</b-button
            >
          </div>
          <InstrumentHeader
            :Instrument="questionTest"
            :TestForm="true"
            :allows_crud="allows_crud"
          ></InstrumentHeader>
        </div>
      </template> -->
      <template>
        <SpecificationTable
          class="mt-2"
          :evaluation_id="evaluation_id"
          :question_test_id="QuestionTest ? QuestionTest.id : null"
          :allows_crud="allows_crud"
          :print_event="print_event"
          :observation_table="observation_table"
          :disabled_redirect_btn="!question_test_id ? false : true"
          @multipleChoiceScoreChanged="multipleChoiceScoreChanged"
          @specificationTableQuestion="specificationTableQuestion"
          @specificationTableDimension="specificationTableDimension"
          @printViewMode="printViewChanged"
        ></SpecificationTable>
      </template>
    </div>

    <!-- <fab
      position="bottom-right"
      bg-color="var(--header-color)"
      :actions="fab_actions"
      title="Navegar a"
      @redirectToTestView="redirectToTestView"
    ></fab>-->
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";

export default {
  name: "SpecificationTableView",
  components: {
    SpecificationTable: () => import("./SpecificationTable"),
    // InstrumentHeader: () => import("../InstrumentHeader"),
    // PrintFab: () => import("@/components/reusable/PrintFab"),
    // fab: () => import("vue-fab")
  },
  props: {
    QuestionTest: {
      type: Object,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
    observation_table: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      evaluation_id: Number(this.$route.params.evaluation_id),
      question_test_id: Number(this.$route.params.question_test_id),
      question_types: [],
      collapse_visible: false,
      fab_actions: [
        {
          name: "redirectToTestView",
          icon: "cached",
          tooltip: "Examen",
        },
      ],
      print_event: false,
      print_view: false,
    };
  },
  computed: {
    ...mapGetters({
      matters: names.MATTERS,
      studyUnits: names.STUDY_UNITS,
      learningResults: names.LEARNING_RESULTS,
      sessionPlannings: names.SESSION_PLANNINGS,
      sections: names.SECTIONS,
      performanceIndicators: names.PERFORMANCE_INDICATORS,
      evaluationCriterias: names.EVALUATION_CRITERIAS,
      procedureEvidences: names.PROCEDURE_EVIDENCES,
      studyUnitcontents: names.CONTENTS,
      getEvaluation: names.EVALUATION,
      allQuestions: names.QUESTIONS,
      questionTests: names.QUESTION_TESTS,
    }),
    evaluation() {
      return this.getEvaluation(this.evaluation_id);
    },
    questionTest() {
      return this.questionTests.find((x) => x.evaluation == this.evaluation_id);
    },
    studyUnit() {
      if (!this.evaluation) return null;
      return this.studyUnits.find((x) => x.id == this.evaluation.study_unit);
    },
    learningResult() {
      if (!this.evaluation) return null;
      return this.learningResults.find(
        (x) => x.id == this.evaluation.learning_result
      );
    },
    questions() {
      return this.allQuestions.filter(
        (x) => x.evaluation == this.evaluation.id
      );
    },
  },
  methods: {
    redirectToTestView() {
      if (isNaN(this.evaluation.id)) {
        toast("El Examen no ha sido creado.");
        return;
      }
      this.$router.push({
        name: "ExamView",
        params: { test_id: this.evaluation.id },
      });
    },
    multipleChoiceScoreChanged(item) {
      this.$emit("multipleChoiceScoreChanged", item);
    },
    specificationTableQuestion(item) {
      this.$emit("specificationTableQuestion", item);
    },
    specificationTableDimension(item) {
      this.$emit("specificationTableDimension", item);
    },
    // generateReport() {
    //   if (!this.allows_crud) {
    //     this.print_event = !this.print_event;
    //     setTimeout(() => {
    //       window.print();
    //       this.print_event = !this.print_event;
    //     }, 300);
    //   } else {
    //     this.$swal({
    //       title: `Debe salir del modo edición antes de poder imprimir!`,
    //       type: "warning",
    //       showCancelButton: false,
    //     });
    //   }
    // },
    printViewChanged(state) {
      if (state) {
        this.print_view = state;
      }
    },
    generateReport() {
      this.print_event = !this.print_event;
      this.$emit("printerEvent", this.print_event);
      setTimeout(() => {
        // const modal = document.getElementById("modalInvoice");
        // const cloned = modal.cloneNode(true);
        // let section = document.getElementById("print");

        // if (!section) {
        //   section = document.createElement("div");
        //   section.id = "print";
        //   document.body.appendChild(section);
        // }

        // section.innerHTML = "";
        // section.appendChild(cloned);
        window.print();
        this.print_event = !this.print_event;
        this.$emit("printerEvent", this.print_event);
      }, 300);
    },
  },
  watch: {},
  mounted() {
    this.$store.dispatch(names.FETCH_QUESTION_TESTS, this.evaluation_id);
  },
  created() {
    this.$hasPermissions();
    this.$store.dispatch(names.FETCH_QUESTION_TYPES);
    this.$store.dispatch(names.FETCH_QUESTIONS, this.evaluation_id);
    this.$store.dispatch(names.FETCH_TAXONOMIES);
    this.$store.dispatch(names.FETCH_QUESTION_TESTS, this.evaluation_id);
    this.$store
      .dispatch(names.FETCH_SECTION_EVALUATIONS, this.evaluation_id)
      .then((response) => {
        response.forEach((section_evaluation) => {
          section_evaluation.evaluation_criterias_micro.forEach(
            (ra_micro_id) => {
              this.$store.dispatch(
                names.FETCH_EVALUATION_CRITERIA,
                ra_micro_id
              );
            }
          );
          section_evaluation.evaluation_criterias_macro.forEach(
            (ra_macro_id) => {
              this.$store.dispatch(
                names.FETCH_EVALUATION_CRITERIA_MACRO,
                ra_macro_id
              );
            }
          );
        });
      });
  },
};
</script>

<style scoped>
.upper-container {
  margin-bottom: 2% !important;
}
.specification-table-header {
  display: flex;
  margin-bottom: 0.2em;
  justify-content: center;
}
.specification-table-title {
  font-size: 21pt;
}
.btn-go-back {
  margin-left: 0;
  margin-right: auto;
  max-height: 2.4em;
}
.btn-icon {
  transform: translateY(10%);
}
.printer {
  background: var(--kl-thirth-color) !important;
  color: var(--secondary-font-color);
  margin-left: auto;
  max-height: 2.4em;
  right: 0 !important;
}
.printer:hover {
  background: var(--kl-thirth-hover-color) !important;
}
textarea {
  width: 100%;
}
.form-row .form-group {
  margin: 0px;
  padding: 0px;
}
.header-container {
  display: flex;
  flex-direction: row;
}
.container-div {
  margin-top: 0.4em;
  margin-right: 0.5em;
}
.modal-printer {
  z-index: 1000 !important;
}
.header-div {
  background-color: white;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
}
.row-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0px;
  padding: 0px;
  width: 100%;
}
.col-div {
  width: 100%;
  margin: 0px;
  padding: 0px;
}
</style>